import React from "react";
import SEO from "../seo";
import LayoutBase from "../shared/LayoutBase";
import NotFoundHeader from "./NotFoundHeader";

const NotFoundPage = () => (
	<LayoutBase
		header={<NotFoundHeader/>}
	>
		<SEO title="404: Not found"/>
		<h1>NOT FOUND</h1>
		<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
	</LayoutBase>
);

export default NotFoundPage;
