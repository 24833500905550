import React from "react";
import { PagePath } from "../../helpers/PageHelper";
import LoginContainerView, { LoginContainerStyle } from "../LoginContainerView";
import HeaderContainer from "../shared/HeaderContainer";
import HeaderDecor from "../shared/HeaderDecor";
import HeaderExternalLink from "../shared/HeaderExternalLink";
import HeaderInternalLink from "../shared/HeaderInternalLink";
import HeaderPanel from "../shared/HeaderPanel";

const Header = () => (
	<HeaderContainer>
		<HeaderPanel>
			<HeaderInternalLink href="/">Main</HeaderInternalLink>
			<HeaderDecor/>
			<HeaderExternalLink href="https://medium.com/@tribalbooks">Blog</HeaderExternalLink>
		</HeaderPanel>
		<HeaderPanel>
			<HeaderInternalLink href={PagePath.CARDS}>My collection</HeaderInternalLink>
			<LoginContainerView styleType={LoginContainerStyle.HEADER}/>
		</HeaderPanel>
	</HeaderContainer>
);

export default Header;
